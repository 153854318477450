// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Object) => ({
  root: {
    // marginBottom: theme.spacing.unit * 2,
    // margin: theme.spacing.unit * 2,
    ...theme.mixins.gutters()
  }
});

type Props = {
  classes: Object
};

class DashboardFilter extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h3">
          Dashboard View
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardFilter);
