// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme: Object) => ({
  root: {},
  image: {
    width: "100%"
  }
});

type Props = {
  classes: Object,
  url: string
};

class PhotoNoteDetail extends React.Component<Props> {
  render() {
    const { classes, url } = this.props;
    return (
      <div className={classes.root}>
        <img alt={url} src={url} className={classes.image} />
      </div>
    );
  }
}

export default withStyles(styles)(PhotoNoteDetail);
