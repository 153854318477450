import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Route } from "react-router";
import { connect } from "react-redux";
import Loader from "../components/loader";
import type { AuthState , UserState } from "../_reducers";

import { authActions } from "../_actions";
import { authRoles } from "../_constants";

type Props = {
  auth: AuthState,
  user: UserState,
  checkUser: Function
};

class PrivateRoute extends React.Component<Props> {
  componentDidMount() {
    this.props.checkUser();
    this.unlisten = this.props.history.listen(() => {
      this.props.checkUser();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  checkRole = ({ roleId, path }: { roleId: number, path: string }) => {
    const roles = authRoles[path] || [];
    return roles.indexOf(roleId) > -1;
  };

  render() {
    const { component: Component, auth, path, user, ...rest } = this.props;
    const { userId, roleId } = user;
    const { authenticated, checked } = auth;
    if (!checked) return <Loader />;
    if (checked && authenticated && userId === "" && roleId === -1)
      return <Loader />;
    if (
      checked &&
      userId !== "" &&
      path !== "/not-authorized" &&
      !this.checkRole({ roleId, path })
    ) {
      return (
        <Redirect
          to={{
            pathname: "/not-authorized",
            state: { from: this.props.location }
          }}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={props => {
          return authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  checkUser: authActions.checkUser
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoute));
