import React from 'react'
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from './courses-table'

const styles = (theme: Object) => ({
  root: {
    margin: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#f9f9f9",
    color: "#000000",
    width: "100%",
    height: "100%"
  }
});


const DashboardTable = ({ classes, courses }) => {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={1}>
        <Table data={courses} />
      </Paper>
    </div>
  )
}

export default withStyles(styles)(DashboardTable)
