//@flow

import update from "immutability-helper";
import { feedConstants } from "../_constants";

export type FeedState = {
  post: Array<Object>,
  comments: Array<Object>,
  index: number,
  limit: number,
  query: string,
  from: string,
  selectedSchool: number,
  selectedClass: string,
  postTypes: number,
  hasMore: boolean
};

const defaultState = {
  posts: [],
  comments: [],
  index: 0,
  limit: 10,
  query: "",
  from: "Classmates",
  selectedSchool: -1,
  selectedClass: "",
  postTypes: -1,
  hasMore: true
};

type Action = {
  type: string,
  payload: Object
};

const feedReducers = (
  state: FeedState = defaultState,
  { type, payload }: Action
) => {
  switch (type) {
  case feedConstants.FETCH_FEED_SUCCESS:
    return update(state, {
      posts: { $set: payload.posts },
      hasMore: {
        $apply: b => {
          if (payload.posts.length < state.limit) return false;
          return true;
        }
      }
    });
  case feedConstants.GET_POST_COMMENTS_REQUEST:
    return update(state, {
      comments: { $set: [] }
    });
  case feedConstants.GET_POST_COMMENTS_SUCCESS:
    return update(state, {
      comments: { $set: payload.comments }
    });
  case feedConstants.GET_POST_COMMENTS_ERROR:
    return update(state, {
      comments: { $set: [] }
    });
  case feedConstants.UPDATE_FILTER_FIELD_SUCCESS:
    return update(state, {
      [payload.name]: { $set: payload.value }
    });
  case feedConstants.INCREASE_LIMIT_SUCCESS:
    return update(state, {
      limit: { $apply: b => b + payload.limit }
    });
  case feedConstants.DELETE_POST_SUCCESS:
    return update(state, {
      posts: {
        $apply: b => {
          return b.filter(item => {
            return !(
              item.feed_id === payload.feedId &&
                item.user_id === payload.userId
            );
          });
        }
      }
    });
  default:
    return state;
  }
};

export default feedReducers;
