//@flow

export const authConstants = {
  CHANGE_AUTH_FIELD_REQUEST: "CHANGE_AUTH_FIELD_REQUEST",
  CHANGE_AUTH_FIELD_SUCCESS: "CHANGE_AUTH_FIELD_SUCCESS",
  CHANGE_AUTH_FIELD_ERROR: "CHANGE_AUTH_FIELD_ERROR",
  LOG_IN_USER_REQUEST: "LOG_IN_USER_REQUEST",
  LOG_IN_USER_SUCCESS: "LOG_IN_USER_SUCCESS",
  LOG_IN_USER_ERROR: "LOG_IN_USER_ERROR",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHECK_USER_REQUEST: "CHECK_USER_REQUEST",
  CHECK_USER_SUCCESS: "CHECK_USER_SUCCESS",
  CHECK_USER_ERROR: "CHECK_USER_ERROR",
  LOG_OUT_USER_REQUEST: "LOG_OUT_USER_REQUEST",
  LOG_OUT_USER_SUCCESS: "LOG_OUT_USER_SUCCESS",
  LOG_OUT_USER_ERROR: "LOG_OUT_USER_ERROR"
};

export const authTexts = {
  EMAIL_INVALID: "Email is invalid",
  PASSWORD_REQURED: "Password required",
  PASSWORD_LENGTH: "Password min length is 8",
  PASSWORDS_DONT_MATCH: "Password don't match",
  PASSWORD_UPPERCASE: "Password must contain an uppercase letter",
  PASSWORD_LOWERCASE: "Password must contain a lowercase letter"
};

export const authRoles = {
  "/not-authorized": [1, 2], //2: K12:Teacher
  "/": [1, 2],
  "/feed": [1, 2],
  "/users": [1],
  "/users/:userId": [1],
  "/reports": [1]
};

export const authComponents = {
  header: {
    users: [1],
    reports: []
  },
  "feed-item": {
    avatar: [1],
    name: [1],
    stats: [1],
    comments: {
      3: [1],
      4: [1],
      5: [1],
      6: [1, 2],
      avatar: [1],
      name: [1],
      created: [1]
    },
    delete: [1]
  },
  "feed-filter": {
    search: [1],
    schools: [1]
  },
  modal: {
    name: [1]
  }
};
