//@flow

export const apiConstants = {
  API_NAME: "DashboardAPIV1",
  API_NAME_V11: "DashboardAPIV11",
  // API_URL: "https://dev-api.circleinapp.com/v1/admin",
  API_URI: process.env.REACT_APP_API_URI,
  API_URI_V11: process.env.REACT_APP_API_URI_V11,
  FEED: "/feed",
  USER_CLASSES: "/user/classes",
  PROFILE: ({ userId }) => `/user/${userId}/profile?token=NotRequired`
};
