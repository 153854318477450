//@flow

export const usersConstants = {
  GET_USERS_USER_INFO_REQUEST: "GET_USERS_USER_INFO_REQUEST",
  GET_USERS_USER_INFO_SUCCESS: "GET_USERS_USER_INFO_SUCCESS",
  GET_USERS_USER_INFO_ERROR: "GET_USERS_USER_INFO_ERROR",
  CLEAR_USERS_STORE_REQUEST: "CLEAR_USERS_STORE_REQUEST",
  CLEAR_USERS_STORE_SUCCESS: "CLEAR_USERS_STORE_SUCCESS",
  CLEAR_USERS_STORE_ERROR: "CLEAR_USERS_STORE_ERROR",
  GET_USERS_USER_POINTS_REQUEST: "GET_USERS_USER_POINTS_REQUEST",
  GET_USERS_USER_POINTS_SUCCESS: "GET_USERS_USER_POINTS_SUCCESS",
  GET_USERS_USER_POINTS_ERROR: "GET_USERS_USER_POINTS_ERROR",
  GET_USERS_USER_SERVICE_REQUEST: "GET_USERS_USER_SERVICE_REQUEST",
  GET_USERS_USER_SERVICE_SUCCESS: "GET_USERS_USER_SERVICE_SUCCESS",
  GET_USERS_USER_SERVICE_ERROR: "GET_USERS_USER_SERVICE_ERROR"
};
