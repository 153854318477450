// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";
import Header from "../containers/header";
import DashboardContainer from "../containers/dashboard";
import { analyticServices } from "../_services";

const styles = (theme: Object) => ({
  root: {}
});

type Props = {
  classes: Object
};

class Dashboard extends React.Component<Props> {
  componentDidMount = () => {
    const { match = {} } = this.props;
    const { path = "" } = match;
    analyticServices.pageView({ path });
  };

  render() {
    const { classes } = this.props;
    return (
      <Header>
        <div className={classes.root}>
          <DashboardContainer />
        </div>
      </Header>
    );
  }
}

export default withRoot(withStyles(styles)(Dashboard));
