import { combineReducers } from "redux";
import authReducers from "./auth.reducers";
import dialogReducers from "./dialog.reducers";
import feedReducers from "./feed.reducers";
import userReducers from "./user.reducers";
import usersReducers from "./users.reducers";
import metricsReducer from "./metrics.reducers";
import reportsReducer from "./reports.reducers";
import coursesReducer from "./courses.reducers";

const rootReducer = combineReducers({
  auth: authReducers,
  dialog: dialogReducers,
  feed: feedReducers,
  user: userReducers,
  users: usersReducers,
  metrics: metricsReducer,
  courses: coursesReducer,
  reports: reportsReducer
});

export default rootReducer;
