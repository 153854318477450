//@flow

import update from "immutability-helper";
import { reportsConstants } from "../_constants";

export type ReportsState = {
  comments: Array<Object>,
  posts: Array<Object>,
  tab: number
};

const defaultState = {
  comments: [],
  posts: [],
  tab: 0
};

type Action = {
  type: string,
  payload: Object
};

const reportsReducers = (
  state: ReportsState = defaultState,
  { type, payload }: Action
) => {
  switch (type) {
  case reportsConstants.FETCH_REPORTS_SUCCESS:
    return update(state, {
      comments: { $set: payload.comments },
      posts: { $set: payload.posts }
    });
  case reportsConstants.UPDATE_FILTER_TAB_SUCCESS:
    return update(state, {
      tab: { $set: payload.tab }
    });
  default:
    return state;
  }
};

export default reportsReducers;
