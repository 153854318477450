// @flow

import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = (theme: Object) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing.unit
  }
});

type Props = {
  classes: Object
};

class NotAuthorized extends React.Component<Props> {
  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom>
          You are not authorized to see this page
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={this.handleGoBack}
        >
          Go back
        </Button>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NotAuthorized));
