// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme: Object) => ({
  root: {
    marginBottom: theme.spacing.unit * 2
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  }
});

type Props = {
  classes: Object,
  tab: number,
  handleChange: Function
};

class ReportsFilter extends React.Component<Props> {
  render() {
    const { classes, tab, handleChange } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="h5" component="h3">
            Reports
          </Typography>
          <Tabs value={tab} onChange={handleChange} textColor="secondary">
            <Tab label="Posts" />
            <Tab label="Comments" />
          </Tabs>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ReportsFilter);
