import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useTable, useSortBy } from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    fontSize: 20,
    color: theme.palette.common.black,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-ty/pe(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const CoursesTable = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Course Name',
        accessor: 'class_name',
      },
      {
        Header: 'Section',
        accessor: 'section_id',
      },
      {
        Header: 'Download Count',
        accessor: 'num_students',
      },
      {
        Header: 'Faculty Name',
        accessor: 'staff_name',
      },
    ],
    []
  )

  const {
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy)

  const tableStyle = {
    textAlign: 'center',
    width: '100%',
    fontSize: 20,
    color: 'black',
  }

  return (
    <MaUTable style={tableStyle}> 
      <TableHead>
        {headerGroups.map((headerGroup,k) => (
          <StyledTableRow key={`header-${k}`}> 
            {headerGroup.headers.map(column => {
              const { onClick, style, key } =column.getHeaderProps(column.getSortByToggleProps())
              const { isSorted, isSortedDesc } = column
              return(
                <StyledTableCell
                  key={key}
                  style={style}
                  onClick={onClick}
                > 
                  <TableSortLabel
                    active={isSorted}
                    direction={isSortedDesc ? 'desc' : 'asc'}
                  >
                    {column.render('Header')}
                  </TableSortLabel>
                </StyledTableCell>
              )})}
          </StyledTableRow>
        ))}
      </TableHead>
      <TableBody> 
        {rows.map(
          (row, k) => {
            prepareRow(row);
            return (
              <TableRow key={k}> 
                {row.cells.map((cell, k) => {
                  return <StyledTableCell key={`row-${k}`}>{cell.render('Cell')}</StyledTableCell>
                })}
              </TableRow>
            )}
        )}
      </TableBody>
    </MaUTable>
  )
}

export default CoursesTable
