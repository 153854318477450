// @flow

import React from "react";
// import { FormattedNumber } from "react-intl";
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Object) => ({
  root: {
    minWidth: 120,
    height: "100%"
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    justifyContent: "space-between",
    backgroundColor: "#f9f9f9",
    color: "#000000"
  },
  textAlignCenter: {
    textAlign: "center"
  }
});

type Props = {
  classes: Object,
  title: string,
  content: number
};

class DashboardTile extends React.Component<Props> {
  render() {
    const { classes, title, content } = this.props;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            disableTypography
            title={
              <Typography
                variant="subtitle1"
                align="left"
                color="inherit"
                // className={classes.textAlignCenter}
              >
                {title}
              </Typography>
            }
            className={classes.textAlignCenter}
          />
          <CardContent>
            <Typography
              variant="h4"
              align="left"
              color="inherit"
              // lassName={classes.textAlignCenter}
            >
              {/* <FormattedNumber value={content || 0} /> */}
              {numeral(content || 0)
                .format(title === "Points" ? "0.0a" : "0a")
                .toUpperCase()}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardTile);
