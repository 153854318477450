import React from "react";
import { Switch } from "react-router";
import Index from "../pages/index";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import Reports from "../pages/reports";
// import Feed from "../pages/feed";
import Users from "../pages/users";
import NotAuthorized from "../pages/not-authorized";
import changePassword from "../pages/change-password";
import PrivateRoute from "./private-route";
import AuthRoute from "./auth-route";

const routes = (
  <div>
    <Switch>
      <AuthRoute path="/login" component={Login} />
      <AuthRoute path="/change-password" component={changePassword} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/feed" component={Index} />
      <PrivateRoute exact path="/reports" component={Reports} />
      {/* <PrivateRoute exact path="/feed" component={Feed} /> */}
      <PrivateRoute exact path="/users/:userId" component={Users} />
      <PrivateRoute exact path="/not-authorized" component={NotAuthorized} />
    </Switch>
  </div>
);

export default routes;
