//@flow

import { reportsConstants } from "../_constants";
import { reportsServices, analyticServices } from "../_services";
import { dialogActions } from ".";

const fetchReports = () => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await reportsServices.fetchReports();
      const { comments, posts } = response;
      dispatch(success({ comments, posts }));
      analyticServices.event({ category: "Reports", action: "Fetch" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Reports",
        action: "Fetch Error",
        label: err
      });
    }
  };
  function request() {
    return { type: reportsConstants.FETCH_REPORTS_REQUEST };
  }
  function success({ comments, posts }) {
    return {
      type: reportsConstants.FETCH_REPORTS_SUCCESS,
      payload: {
        comments,
        posts
      }
    };
  }
  function error(err) {
    return {
      type: reportsConstants.FETCH_REPORTS_ERROR,
      payload: { error: err }
    };
  }
};

const updateFilterTab = ({ tab }) => {
  return async dispatch => {
    try {
      dispatch(request());
      dispatch(success({ tab }));
      analyticServices.event({ category: "Reports", action: "Update Tab" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Reports",
        action: "Update Tab Error",
        label: err
      });
    }
  };
  function request() {
    return { type: reportsConstants.UPDATE_FILTER_TAB_REQUEST };
  }
  function success({ tab }) {
    return {
      type: reportsConstants.UPDATE_FILTER_TAB_SUCCESS,
      payload: {
        tab
      }
    };
  }
  function error(err) {
    return {
      type: reportsConstants.UPDATE_FILTER_TAB_ERROR,
      payload: { error: err }
    };
  }
};

export const reportsActions = {
  fetchReports,
  updateFilterTab
};
