// @flow

import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import type { ReportsState } from "../_reducers/reports.reducers";
import { reportsActions } from "../_actions";
import ReportsFilter from "../components/reports-filter";

const styles = (theme: Object) => ({
  root: {}
});

type Props = {
  classes: Object,
  reports: ReportsState
};

class ReportsFilterContainer extends React.Component<Props> {
  handleChange = (event, value) => {
    this.props.updateFilterTab({ tab: value });
  };

  render() {
    const { classes, reports } = this.props;
    const { tab } = reports;
    return (
      <div className={classes.root}>
        <ReportsFilter tab={tab} handleChange={this.handleChange} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateFilterTab: reportsActions.updateFilterTab
};

const mapStateToProps = state => {
  return {
    reports: state.reports
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReportsFilterContainer));
