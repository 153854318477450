// @flow

import React from "react";
import type { ComponentType } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// import blue from "@material-ui/core/colors/blue";
// import green from "@material-ui/core/colors/green";
import CssBaseline from "@material-ui/core/CssBaseline";
import AlertDialog from "./containers/alert-dialog";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      // light: purple[300],
      main: "#161D26"
      // dark: purple[700],
    },
    secondary: {
      main: "#49afd9"
    },
    text: {
      primary: "rgba(255, 255, 255, 0.87)",
      secondary: "rgba(255, 255, 255, 0.54)",
      disabled: "rgba(255, 255, 255, 0.38)",
      hint: "rgba(255, 255, 255, 0.38)"
    },
    background: {
      default: "#203446",
      paper: "#161D26"
    },
    action: {
      active: "rgba(255, 255, 255, 0.54)",
      hover: "rgba(255, 255, 255, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255, 255, 255, 0.14)",
      disabled: "rgba(255, 255, 255, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)"
    }
    // secondary: {
    //   light: blue[300],
    //   main: blue[500],
    //   dark: blue[700]
    // }
  }
});

function withRoot(Component: ComponentType<*>) {
  function WithRoot(props: Object) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AlertDialog />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
