// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const styles = (theme: Object) => ({
  root: {
    margin: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    height: "100%"
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#f9f9f9",
    color: "#000000",
    height: "100%"
  },
  step: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    color: "white"
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  button: {
    margin: theme.spacing.unit
  },
  icon: {
    marginRight: theme.spacing.unit
  },
  link: {
    color: "#03a9f4"
  }
});

type Props = {
  classes: Object
};

class DashboardSteps extends React.Component<Props> {
  handleSave = () => {
    window.open(
      `${process.env.PUBLIC_URL}/docs/CircleIn - Classroom Poster.pdf`,
      "_blank"
    );
  };

  render() {
    const { classes } = this.props;
    // eslint-disable-next-line no-script-url
    const dudUrl = "javascript:;";
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="h6" paragraph color="inherit">
            Top 5 Ways to Encourage Student Engagement on CircleIn
          </Typography>
          <Typography variant="subtitle1" paragraph color="inherit">
            1. After a lesson, encourage students to post their class notes on
            CircleIn (10K points for sharing)
          </Typography>
          <Typography variant="subtitle1" paragraph color="inherit">
            2. Encourage student collaboration through asking questions and
            getting feedback on CircleIn (25K points for best answer)
          </Typography>
          <Typography variant="subtitle1" paragraph color="inherit">
            3. Have students add reminders on CircleIn when you share important
            due dates (300 points for setting up a reminder)
          </Typography>
          <Typography variant="subtitle1" paragraph color="inherit">
            4. Before a test, remind students to study in groups on CircleIn
            (20K points for initiating a session, 50k additional points after a
            10-minute session)
          </Typography>
          <Typography variant="subtitle1" paragraph color="inherit">
            5. Place a CircleIn poster in your classroom. 
            <Link
              className={classes.link}
              href={dudUrl}
              onClick={this.handleSave}
            >
              Download the poster by clicking here!
            </Link>
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardSteps);
