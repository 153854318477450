//@flow
import isEmail from "isemail";
import { authTexts } from "../_constants";

const validateEmail = (email: string) =>
  !isEmail.validate(email) ? authTexts.EMAIL_INVALID : "";

const validatePassword = (password: string) => {
  if (password.trim() === "") return authTexts.PASSWORD_REQURED;
  if (password.trim().length < 6) return authTexts.PASSWORD_LENGTH;
  if (password.trim().toUpperCase() === password)
    return authTexts.PASSWORD_LOWERCASE;
  if (password.trim().toLowerCase() === password)
    return authTexts.PASSWORD_UPPERCASE;
  return "";
};

const validatePasswordMatch = (password: string, confirmPassword: string) => {
  if (password.trim() !== confirmPassword.trim())
    return authTexts.PASSWORDS_DONT_MATCH;
  return "";
};

const validatePasswordLogin = (password: string) => {
  if (password.trim() === "") return authTexts.PASSWORD_REQURED;
  return "";
};

export const authHelpers = {
  validateEmail,
  validatePassword,
  validatePasswordMatch,
  validatePasswordLogin
};
