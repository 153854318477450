// @flow

import React from "react";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import { authComponents } from "../_constants";

const styles = (theme: Object) => ({
  root: {
    marginBottom: theme.spacing.unit * 2
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.secondary.main, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.secondary.main, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto"
    },
    color: theme.palette.text.primary
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200
  }
});

type Props = {
  classes: Object,
  roleId: number,
  userClasses: Array<Object>,
  query: string,
  from: string,
  selectedSchool: number,
  selectedClass: string,
  postTypes: string,
  handleChange: Function
};

class FeedFilter extends React.Component<Props> {
  checkSubject = ({ subject }) => {
    if (subject === "AP (Advanced Placement)") return "AP";
    if (subject === "Honors") return "Honors";
    return "";
  };

  render() {
    const {
      classes,
      roleId,
      userClasses,
      query,
      // from,
      selectedSchool,
      selectedClass,
      postTypes,
      handleChange
    } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="h5" component="h3">
            Feed
          </Typography>
          {authComponents["feed-filter"].search.indexOf(roleId) > -1 && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                value={query}
                name="query"
                onChange={handleChange}
              />
            </div>
          )}
          {authComponents["feed-filter"].schools.indexOf(roleId) > -1 && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.schoolsLabelRef = ReactDOM.findDOMNode(ref);
                }}
                htmlFor="outlined-selectedSchool-simple"
              >
                Schools
              </InputLabel>
              <Select
                value={selectedSchool}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    labelWidth={
                      this.schoolsLabelRef
                        ? this.schoolsLabelRef.offsetWidth
                        : 0
                    }
                    name="selectedSchool"
                    id="outlined-selectedSchool-simple"
                  />
                }
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={95977}>Pinecrest Academy - Inspirada</MenuItem>
                <MenuItem value={137144}>Pinecrest Academy - Cadence</MenuItem>
                <MenuItem value={137145}>Pinecrest Academy - St. Rose</MenuItem>
                <MenuItem value={95589}>
                  Coral Academy of Science Las Vegas
                </MenuItem>
                <MenuItem value={137146}>Democracy Prep</MenuItem>
                <MenuItem value={1}>Western Nevada College</MenuItem>
                <MenuItem value={2}>Nevada State College</MenuItem>
              </Select>
            </FormControl>
          )}
          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.labelRef = ReactDOM.findDOMNode(ref);
              }}
              htmlFor="outlined-from-simple"
            >
              Posts from
            </InputLabel>
            <Select
              value={from}
              onChange={handleChange}
              input={
                <OutlinedInput
                  labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                  name="from"
                  id="outlined-from-simple"
                />
              }
            >
              <MenuItem value="Everyone">Everyone</MenuItem>
              <MenuItem value="Classmates">Classmates</MenuItem>
              <MenuItem value="My Posts">Me</MenuItem>
              <MenuItem value="Bookmarks">Bookmarks</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.classesLabelRef = ReactDOM.findDOMNode(ref);
              }}
              htmlFor="outlined-selectedClass-simple"
            >
              Classes
            </InputLabel>
            <Select
              value={selectedClass}
              onChange={handleChange}
              input={
                <OutlinedInput
                  labelWidth={
                    this.classesLabelRef ? this.classesLabelRef.offsetWidth : 0
                  }
                  name="selectedClass"
                  id="outlined-selectedClass-simple"
                />
              }
            >
              <MenuItem value={0}>All</MenuItem>
              {userClasses.map(item => (
                <MenuItem key={item.class_id} value={item.section_id}>
                  {/* {`${this.checkSubject({ subject: item.subject })} ${
                    item.class
                  }`} */}
                  {`${item.subject}${item.class} - ${item.section}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.postsLabelRef = ReactDOM.findDOMNode(ref);
              }}
              htmlFor="outlined-postTypes-simple"
            >
              Post Types
            </InputLabel>
            <Select
              value={postTypes}
              onChange={handleChange}
              input={
                <OutlinedInput
                  labelWidth={
                    this.postsLabelRef ? this.postsLabelRef.offsetWidth : 0
                  }
                  name="postTypes"
                  id="outlined-postTypes-simple"
                />
              }
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={3}>Flashcards</MenuItem>
              <MenuItem value={4}>Class Notes</MenuItem>
              <MenuItem value={5}>Links</MenuItem>
              <MenuItem value={6}>Questions</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(FeedFilter);
