// @flow

import React from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { withStyles } from "@material-ui/core/styles";
import FeedFilter from "../components/feed-filter";
import type { UserState } from "../_reducers/user.reducers";
import type { FeedState } from "../_reducers/feed.reducers";
import { feedActions } from "../_actions";

const styles = (theme: Object) => ({
  root: {}
});

type Props = {
  classes: Object,
  user: UserState,
  feed: FeedState
};

class FeedFilterContainer extends React.Component<Props> {
  componentDidMount = () => {
    this.applySearch = debounce(this.applySearch, 300);
    // this.props.updateFilterField({ name: "from", value: "Classmates" });
    this.props.updateFilterField({ name: "selectedSchool", value: 0 });
    this.props.updateFilterField({ name: "selectedClass", value: 0 });
    this.props.updateFilterField({ name: "postTypes", value: 0 });
  };

  handleChange = event => {
    this.props.updateFilterField({
      name: event.target.name,
      value: event.target.value
    });
    this.applySearch();
  };

  applySearch = () => {
    const { user, feed, fetchFeed } = this.props;
    const { userId, schoolId } = user;
    const {
      index,
      limit,
      query,
      from,
      selectedSchool,
      selectedClass,
      postTypes
    } = feed;
    // const ids = selectedClass !== "" ? selectedClass.split(",") : ["0", "0"];
    fetchFeed({
      userId,
      index,
      limit,
      schoolId,
      postTypes,
      from,
      // classId: parseInt(ids[0])
      selectedSchool,
      classId: 0,
      // sectionId: parseInt(ids[1]),
      sectionId: selectedClass,
      query
    });
  };

  render() {
    const { classes, user, feed } = this.props;
    const { userClasses, roleId } = user;
    const { query, from, selectedSchool, selectedClass, postTypes } = feed;

    return (
      <div className={classes.root}>
        <FeedFilter
          userClasses={userClasses}
          roleId={roleId}
          query={query}
          from={from}
          selectedSchool={selectedSchool}
          selectedClass={selectedClass}
          postTypes={postTypes}
          handleChange={this.handleChange}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateFilterField: feedActions.updateFilterField,
  fetchFeed: feedActions.fetchFeed
};

const mapStateToProps = state => {
  return {
    user: state.user,
    feed: state.feed
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FeedFilterContainer));
