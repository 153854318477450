//@flow

import { apiConstants } from "../_constants";
import { requestServices } from ".";

const createURL = ({
  userId,
  index,
  limit,
  schoolId,
  postTypes,
  from,
  selectedSchool,
  classId,
  sectionId,
  query
}) => {
  let url = `${apiConstants.FEED}`;
  let queryString = `?index=${index}&limit=${limit}`;
  if (from === "Bookmarks") {
    // return `${url}${userId}/bookmark`;
    return `${url}/bookmark`;
  }

  if (query !== "" && from !== "My Posts") {
    queryString = `${queryString}&query=${query}`;
  }

  if ((from === "Classmates" || query !== "") && selectedSchool !== 0) {
    queryString = `${queryString}&school_id=${selectedSchool}`;
  }

  if (postTypes !== 0) {
    queryString = `${queryString}&tool_type_id=${postTypes}`;
  }

  if (classId !== 0) {
    queryString = `${queryString}&class_id=${classId}`;
  }

  if (sectionId !== 0) {
    queryString = `${queryString}&section_id=${sectionId}`;
  }

  if (from === "My Posts") {
    url = `${url}/user/${userId}${queryString}`;
  } else {
    // url = `${url}${userId}${queryString}`;
    url = `${url}${queryString}`;
  }

  return url;
};

const fetchFeed = async ({
  userId,
  index,
  limit,
  schoolId,
  postTypes,
  from,
  selectedSchool,
  classId,
  sectionId,
  query
}) => {
  try {
    const url = createURL({
      userId,
      index,
      limit,
      schoolId,
      postTypes,
      from,
      selectedSchool,
      classId,
      sectionId,
      query
    });
    return requestServices.get({ path: url });
    // return axios.get(url);
  } catch (error) {
    console.log("Error: ", error);
  }
};

const getPostComments = async ({ userId, postId, typeId }) => {
  try {
    return requestServices.get({
      path: `/feed/${postId}/comments?type_id=${typeId}`
    });
  } catch (error) {
    console.log("Error: ", error);
  }
};

const deletePost = async ({ feedId, userId }) => {
  try {
    return requestServices.del({
      path: `/feed/${feedId}?post_creator_user_id=${userId}`
    });
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const feedServices = {
  fetchFeed,
  getPostComments,
  deletePost
};
