// @flow

import React from "react";
import classNames from "classnames";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import teal from "@material-ui/core/colors/teal";
import Collapse from "@material-ui/core/Collapse";
import LinkPost from "../assets/ic_link_post.svg";
import QuestionPost from "../assets/ic_question_post.svg";
import FlashcardPost from "../assets/ic_flashcard_post.svg";
import Notes from "../assets/default.jpg";

const styles = (theme: Object) => ({
  root: {
    // flex: "1 1 0"
    // maxWidth: 800
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    height: "100%",
    flex: 1
  },
  main: {
    display: "flex",
    width: "100%"
  },
  details: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%"
  },
  avatar: {
    // margin: theme.spacing.unit
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  bigAvatar: {
    width: 60,
    height: 60,
    marginBottom: 10
  },
  content: {
    paddingLeft: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  flashCardsImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  deckCount: {
    width: "100%",
    backgroundColor: teal[500],
    textAlign: "center",
    padding: 2,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  notesImage: {
    minHeight: 75,
    maxHeight: 75,
    minWidth: 75,
    maxWidth: 75,
    borderRadius: 5
  }
});

type Props = {
  classes: Object,
  title: string,
  description: string,
  firstName: string,
  lastName: string,
  reporterFirstName: string,
  reporterLastName: string,
  reportCreatedAt: string,
  postType: number,
  comments: number,
  thanks: number,
  views: number,
  postCreatedAt: number,
  reasonId: number,
  reportCreatedAt: string,
  reporterFirstName: string,
  reporterLastName: string,
  reportTitle: string,
  reportDescription: string
};

type State = {
  open: boolean
};

class ReportItem extends React.Component<Props, State> {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState(prev => ({ open: !prev.open }));
  };

  renderPostType = ({ postType }: { postType: number }) => {
    const { classes } = this.props;
    switch (postType) {
    case 3:
      return (
        <div className={classes.flashCardsImage}>
          <img src={FlashcardPost} alt="flashcards" />
          <div className={classes.deckCount}>
            <Typography variant="caption">2 Cards</Typography>
          </div>
        </div>
      );
    case 4:
      return (
        <div
          className={classes.notesImage}
          style={{
            // $FlowIgnore
            background: `url(${Notes})`,
            backgroundSize: "cover"
          }}
        />
      );
    case 5:
      return <img src={LinkPost} alt="link" />;
    case 6:
      return <img src={QuestionPost} alt="question" />;
    default:
      return "";
    }
  };

  render() {
    const {
      classes,
      title,
      description,
      firstName,
      lastName,
      postType,
      comments,
      thanks,
      views,
      postCreatedAt,
      reasonId,
      reportCreatedAt,
      reporterFirstName,
      reporterLastName,
      reportTitle,
      reportDescription
    } = this.props;
    const { open } = this.state;
    const name = `${firstName} ${lastName}`;
    const initials = name && name !== "" ? name.match(/\b(\w)/g).join("") : "";
    return (
      <div className={classes.root}>
        <Paper
          className={classes.paper}
          elevation={1}
          onClick={this.handleClick}
        >
          <div className={classes.main}>
            <div className={classes.avatar}>
              <Avatar
                alt="Adelle Charles"
                className={classNames(classes.bigAvatar)}
              >
                {initials}
              </Avatar>
              <Hidden smUp>
                <div className={classes.thumbnail}>
                  {this.renderPostType({ postType })}
                </div>
              </Hidden>
            </div>
            <div className={classes.content}>
              <Typography variant="h5" style={{ wordBreak: "break-word" }}>
                {title}
              </Typography>
              <Typography component="p">{`${name} | History`}</Typography>
              <Typography component="p">
                {`${comments} comments | ${thanks} thanks | ${views} views`}
              </Typography>
              <Typography component="p">
                {postCreatedAt !== "" ? moment(postCreatedAt).fromNow() : "NA"}
              </Typography>
            </div>
            <Hidden xsDown>
              <div className={classes.thumbnail}>
                {this.renderPostType({ postType })}
              </div>
            </Hidden>
          </div>
          <Collapse in={open} style={{ width: "100%" }}>
            <div className={classes.details}>
              <Typography variant="body2">{description}</Typography>
              <Typography variant="body2">{`Reason ID: ${reasonId}`}</Typography>
              <Typography variant="body2">{`Report Created At: ${reportCreatedAt}`}</Typography>
              <Typography variant="body2">{`Reporter First Name: ${reporterFirstName}`}</Typography>
              <Typography variant="body2">{`Reporter Last Name: ${reporterLastName}`}</Typography>
              <Typography variant="body2">{`Reporter Title: ${reportTitle}`}</Typography>
              <Typography variant="body2">{`Reporter Description: ${reportDescription}`}</Typography>
            </div>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ReportItem);
