// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";

const styles = (theme: Object) => ({
  card: {
    // margin: theme.spacing.unit * 2,
    maxWidth: 400
    // maxHeight: 130
  },
  avatar: {
    backgroundColor: red[500]
  }
});

type Props = {
  classes: Object,
  userId: number,
  email: string,
  firstName: string,
  lastName: string,
  school: string,
  schoolId: number
};

class UserProfile extends React.Component<Props> {
  render() {
    const {
      classes,
      userId,
      email,
      firstName,
      lastName,
      school,
      schoolId
    } = this.props;
    if (userId === -1) return null;
    const name = `${firstName} ${lastName}`;
    const initials = name && name !== "" ? name.match(/\b(\w)/g).join("") : "";
    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="Initials" className={classes.avatar}>
              {initials}
            </Avatar>
          }
          title={`${name} (ID: ${userId})`}
          subheader={email}
        />
        <CardContent>
          <Typography component="p">
            {`${school} (schoold ID: ${schoolId})`}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(UserProfile);
