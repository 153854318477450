//@flow

import { requestServices } from ".";
import { apiConstants } from "../_constants";

const getCourses = async (userId: number) => {
  try {
    return requestServices.get({ endpoint: apiConstants.API_NAME_V11, path: `/institution/courses?user_id=${userId}` });
  } catch (error) {
    console.log("Error: ", error);
    return null
  }
};

export const coursesServices = {
  getCourses
};
