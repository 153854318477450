// @flow

import { metricsConstants } from "../_constants";
import { metricsServices, analyticServices } from "../_services";
import { dialogActions } from ".";

const getMetrics = () => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await metricsServices.getMetrics();
      //   console.log(response);
      const {
        csh_earned,
        flashcards_created,
        notes_created,
        questions_created,
        reminders_created,
        thanks_count,
        points
      } = response || {};

      dispatch(
        success({
          csh: csh_earned,
          flashcards: flashcards_created,
          notes: notes_created,
          questions: questions_created,
          reminders: reminders_created,
          thanks: thanks_count,
          points
        })
      );
      analyticServices.event({ category: "Metrics", action: "Get" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Metrics",
        action: "Get",
        label: err
      });
    }
  };
  function request() {
    return { type: metricsConstants.GET_METRICS_REQUEST };
  }
  function success({
    csh,
    flashcards,
    notes,
    questions,
    reminders,
    thanks,
    points
  }) {
    return {
      type: metricsConstants.GET_METRICS_SUCCESS,
      payload: { csh, flashcards, notes, questions, reminders, thanks, points }
    };
  }
  function error(err) {
    return {
      type: metricsConstants.GET_METRICS_ERROR,
      payload: { error: err }
    };
  }
};

export const metricsActions = {
  getMetrics
};
