// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Comment from "./comment";

const styles = (theme: Object) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 400
  }
});

type Props = {
  classes: Object,
  comments: Array<Object>,
  typeId: number,
  roleId: number
};

class Comments extends React.Component<Props> {
  render() {
    const { classes, comments, typeId, roleId } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h6" component="h3" paragraph>
          {typeId === 6 ? "Answers" : "Comments"}
        </Typography>
        {comments.map(item => {
          const elements = [
            <Comment
              key={item.id}
              roleId={roleId}
              firstName={item.user.first_name}
              lastName={item.user.last_name}
              replyTo={item.replyTo}
              body={item.comment}
              created={item.created}
            />
          ];
          const children = item.children.map(child => (
            <Comment
              key={child.id}
              roleId={roleId}
              firstName={child.user.first_name}
              lastName={child.user.last_name}
              replyTo={child.replyTo}
              body={child.comment}
              created={item.created}
              inset
            />
          ));
          elements.push(children);
          return elements;
        })}
      </div>
    );
  }
}

export default withStyles(styles)(Comments);
