//@flow

import update from "immutability-helper";
import { usersConstants } from "../_constants";

export type UsersState = {
  userId: number,
  email: string,
  firstName: string,
  lastName: string,
  school: string,
  schoolId: number,
  points: Array<Object>,
  service: Array<Object>
};

const defaultState = {
  userId: -1,
  email: "",
  firstName: "",
  lastName: "",
  school: "",
  schoolId: -1,
  points: [],
  service: []
};

type Action = {
  type: string,
  payload: Object
};

const usersReducers = (
  state: UsersState = defaultState,
  { type, payload }: Action
) => {
  switch (type) {
  case usersConstants.GET_USERS_USER_INFO_SUCCESS:
    return update(state, {
      userId: { $set: payload.userId },
      email: { $set: payload.email },
      firstName: { $set: payload.firstName },
      lastName: { $set: payload.lastName },
      school: { $set: payload.school },
      schoolId: { $set: payload.schoolId }
    });
  case usersConstants.CLEAR_USERS_STORE_SUCCESS:
    return update(state, {
      $set: defaultState
    });
  case usersConstants.GET_USERS_USER_POINTS_SUCCESS:
    return update(state, {
      points: { $set: payload.points }
    });
  case usersConstants.GET_USERS_USER_SERVICE_SUCCESS:
    return update(state, {
      service: { $set: payload.service }
    });
  default:
    return state;
  }
};

export default usersReducers;
