// @flow

import { coursesConstants } from "../_constants";
import { coursesServices, analyticServices } from "../_services";
import { dialogActions } from ".";

const getCourses = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(request());
      const { user: { userId } } = getState()
      const response = await coursesServices.getCourses(userId);
      
      const {
        courses
      } = response || {};

      dispatch(
        success({
          courses
        })
      );
      analyticServices.event({ category: "Courses", action: "Get" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Courses",
        action: "Get",
        label: err
      });
    }
  };
  function request() {
    return { type: coursesConstants.GET_COURSES_REQUEST };
  }
  function success(payload) {
    return {
      type: coursesConstants.GET_COURSES_SUCCESS,
      payload
    };
  }
  function error(err) {
    return {
      type: coursesConstants.GET_COURSES_ERROR,
      payload: { error: err }
    };
  }
};

export const coursesActions = {
  getCourses
};
