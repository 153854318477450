// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = (theme: Object) => ({
  root: {
    marginTop: theme.spacing.unit * 2
  }
});

type Props = {
  classes: Object,
  deck: Array<Object>
};

class FlashCardsDetail extends React.Component<Props> {
  render() {
    const { classes, deck } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="body1" component="h3" paragraph>
          Flashcards
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography variant="body1" component="h3">
              Questions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h3">
              Answers
            </Typography>
          </Grid>
          {deck.map((item, index) => [
            <Grid key={`q${index}`} item xs={6}>
              <Typography variant="body2" component="h3">
                {item.question}
              </Typography>
            </Grid>,
            <Grid key={`a${index}`} item xs={6}>
              <Typography variant="body2" component="h3">
                {item.answer}
              </Typography>
            </Grid>
          ])}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(FlashCardsDetail);
