// @flow

import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import type { ReportsState } from "../_reducers/reports.reducers";
import { reportsActions } from "../_actions";
import ReportsFilter from "./reports-filter";
import ReportItem from "../components/report-item";

const styles = (theme: Object) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    maxHeight: "100vh"
  },
  list: {
    overflow: "auto",
    marginBottom: 100,
    padding: theme.spacing.unit * 2
  }
});

type Props = {
  classes: Object,
  reports: ReportsState
};

class Reports extends React.Component<Props> {
  mounted: boolean;

  interval: IntervalID;

  init: boolean;

  componentDidMount = () => {
    this.mounted = true;
    this.fetchReports();
  };

  componentWillUpdate = () => {};

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchReports = () => {
    this.props.fetchReports();
  };

  render() {
    const { classes, reports } = this.props;
    const { posts, comments, tab } = reports;
    console.log(reports);
    return (
      <div className={classes.root}>
        <ReportsFilter />
        <div className={classes.list}>
          {tab === 0
            ? posts.map(item => (
              <ReportItem
                key={item.id}
                title={item.title || "No Title"}
                description={item.description || "No Description"}
                firstName={item.object_creator_first_name}
                lastName={item.object_creator_last_name}
                postType={item.type_id}
                comments={item.post_info.comments_count}
                thanks={item.post_info.thanks_count}
                views={item.post_info.view_count}
                postCreatedAt={item.post_info.date}
                reasonId={item.reason_id}
                reportCreatedAt={item.report_created_time}
                reporterFirstName={item.report_creator_first_name}
                reporterLastName={item.report_creator_last_name}
                reportTitle={item.title || "No Title"}
                reportDescription={item.description || "No Description"}
              />
            ))
            : comments.map(item => (
              <ReportItem
                key={item.id}
                title={item.title || "No Title"}
                description={item.description || "No Description"}
                firstName={item.object_creator_first_name || "No First Name"}
                lastName={item.object_creator_last_name || "No Last Name"}
                postType={item.type_id || 0}
                comments={0}
                thanks={0}
                views={0}
                postCreatedAt=""
                reasonId={item.reason_id}
                reportCreatedAt={item.report_created_time}
                reporterFirstName={item.report_creator_first_name}
                reporterLastName={item.report_creator_last_name}
                reportTitle={item.title || "No Title"}
                reportDescription={item.description || "No Description"}
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchReports: reportsActions.fetchReports
};

const mapStateToProps = state => {
  return {
    reports: state.reports
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Reports));
