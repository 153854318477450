// @flow

import React from "react";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ReplyIcon from "@material-ui/icons/Reply";
import { authComponents } from "../_constants";

const styles = (theme: Object) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    display: "flex"
  },
  inset: {
    marginLeft: theme.spacing.unit * 4
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
    marginLeft: theme.spacing.unit * 2
  },
  date: {
    textAlign: "right"
  },
  markdown: {
    "& img": {
      width: "100% !important",
      maxWidth: 400,
      height: "auto !important"
    }
  }
});

type Props = {
  classes: Object,
  roleId: number,
  firstName: string,
  lastName: string,
  body: string,
  replyTo: string,
  created: string,
  inset: boolean
};

class Comment extends React.Component<Props> {
  render() {
    const {
      classes,
      roleId,
      firstName,
      lastName,
      body,
      replyTo,
      created,
      inset = false
    } = this.props;
    const name = `${firstName} ${lastName}`;
    const initials = name !== "" ? name.match(/\b(\w)/g).join("") : "";
    return (
      <div className={classNames(classes.root, inset && classes.inset)}>
        {authComponents["feed-item"].comments.avatar.indexOf(roleId) >
          -1 && <Avatar alt="Remy Sharp">{initials}</Avatar>}
        <div className={classes.content}>
          {authComponents["feed-item"].comments.avatar.indexOf(roleId) >
            -1 && (
            <Typography variant="body2">{`${firstName} ${lastName}`}</Typography>
          )}
          {authComponents["feed-item"].comments.avatar.indexOf(roleId) >
            -1 &&
            replyTo && (
            <Typography color="secondary" variant="body2">
              <ReplyIcon />
              {`Replying to ${replyTo}`}
            </Typography>
          )}
          <Typography
            variant="body1"
            component="div"
            className={classes.markdown}
          >
            <ReactMarkdown source={body} escapeHtml={false} />
          </Typography>
          {authComponents["feed-item"].comments.created.indexOf(roleId) >
            -1 && (
            <div className={classes.date}>
              <Typography variant="body2">
                {moment(created, "YYYY-MM-DD").fromNow()}
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Comment);
