// @flow

import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Header from "../components/header";
import type { UserState } from "../_reducers/user.reducers";
import { authActions } from "../_actions";

const styles = (theme: Object) => ({});

type Props = {
  classes: Object,
  children: Object,
  user: UserState,
  logoutUser: Function
};

class HeaderContainer extends React.Component<Props> {
  handleLogOut = () => {
    this.props.logoutUser();
  };

  render() {
    const { children, user } = this.props;
    const { roleId } = user;
    return (
      <Header roleId={roleId} handleLogOut={this.handleLogOut}>
        {children}
      </Header>
    );
  }
}

const mapDispatchToProps = {
  logoutUser: authActions.logoutUser
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HeaderContainer));
