//@flow

import update from "immutability-helper";
import { metricsConstants } from "../_constants";

export type MetricsState = {
  csh: number,
  flashcards: number,
  notes: number,
  questions: number,
  reminders: number,
  thanks: number,
  points: number
};

const defaultState = {};

type Action = {
  type: string,
  payload: Object
};

const metricsReducers = (
  state: MetricsState = defaultState,
  { type, payload }: Action
) => {
  switch (type) {
  case metricsConstants.GET_METRICS_SUCCESS:
    return update(state, {
      csh: { $set: payload.csh },
      flashcards: { $set: payload.flashcards },
      notes: { $set: payload.notes },
      questions: { $set: payload.questions },
      reminders: { $set: payload.reminders },
      thanks: { $set: payload.thanks },
      points: { $set: payload.points }
    });
  default:
    return state;
  }
};

export default metricsReducers;
