// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const styles = (theme: Object) => ({
  root: {
    margin: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
    // width: "100%",
    // height: "100%"
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#f9f9f9",
    color: "#000000",
    width: "100%",
    height: "100%"
    // minHeight: 400
  },
  link: {
    color: "#03a9f4"
  }
});

type Props = {
  classes: Object
};

class DashboardVideo extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="h6" gutterBottom color="inherit">
            What is CircleIn?
          </Typography>
          <iframe
            title="CircleIn"
            width="100%"
            // height="auto"
            height="400"
            src="https://www.youtube.com/embed/Nd6yBQXi3dI?controls=1"
            // frameBorder="0"
            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <Typography>
            <Link className={classes.link} href="https://circleinapp.com">
              Visit CircleInApp.com to learn more
            </Link>
          </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardVideo);
