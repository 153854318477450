//@flow

import { requestServices } from ".";

const getMetrics = async () => {
  try {
    return requestServices.get({ path: "/metrics" });
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const metricsServices = {
  getMetrics
};
