//@flow

import { requestServices } from ".";

const fetchReports = async () => {
  try {
    return requestServices.get({ path: "/reports" });
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const reportsServices = {
  fetchReports
};
