// @flow

import React from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import FeedFilter from "./feed-filter";
import FeedItem from "../components/feedItem";
import type { UserState } from "../_reducers/user.reducers";
import type { FeedState } from "../_reducers/feed.reducers";
import { feedActions } from "../_actions";

const styles = (theme: Object) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    maxHeight: "100vh"
  },
  list: {
    overflow: "auto",
    marginBottom: 100,
    padding: theme.spacing.unit * 2
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  allPostsLoaded: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

type Props = {
  classes: Object,
  user: UserState,
  feed: FeedState
};

class Feed extends React.Component<Props> {
  mounted: boolean;

  interval: IntervalID;

  init: boolean;

  componentDidMount = () => {
    const { user, feed } = this.props;
    const { userId, schoolId } = user;
    const { from, selectedSchool, selectedClass, postTypes } = feed;
    this.init = false;
    this.mounted = true;
    if (
      userId !== "" &&
      from !== "" &&
      selectedSchool !== "" &&
      selectedClass !== "" &&
      postTypes !== ""
    ) {
      if (schoolId !== -1) {
        this.init = true;
        this.fetchFeed();
        this.initFeed();
      }
    }
  };

  componentDidUpdate = () => {
    const { user, feed } = this.props;
    const { userId, schoolId } = user;
    const { from, selectedSchool, selectedClass, postTypes } = feed;
    if (
      userId !== "" &&
      from !== "" &&
      selectedSchool !== "" &&
      selectedClass !== "" &&
      postTypes !== "" &&
      !this.init
    ) {
      if (schoolId !== -1) {
        this.init = true;
        this.fetchFeed();
        this.initFeed();
      }
    }
  };

  shouldComponentUpdate = nextProps => {
    const { user, feed } = nextProps;
    const { userId, schoolId } = user;
    const { posts, hasMore, comments } = feed;
    if (
      userId !== this.props.user.userId ||
      schoolId !== this.props.user.schoolId ||
      hasMore !== this.props.feed.hasMore ||
      comments !== this.props.feed.comments
    )
      return true;
    if (posts.length !== this.props.feed.posts.length) return true;
    if (!this.init) return true;
    return false;
  };

  componentWillUnmount = () => {
    if (this.interval && this.mounted) {
      this.mounted = false;
      clearInterval(this.interval);
    }
  };

  initFeed = () => {
    this.interval = setInterval(() => {
      this.fetchFeed();
    }, 60000);
  };

  fetchFeed = () => {
    const { user, feed, fetchFeed } = this.props;
    const { userId, schoolId } = user;
    const {
      index,
      limit,
      query,
      from,
      selectedSchool,
      selectedClass,
      postTypes
    } = feed;
    // const ids = selectedClass !== "" ? selectedClass.split(",") : ["0", "0"];
    fetchFeed({
      userId,
      index,
      limit,
      schoolId,
      postTypes,
      from,
      // classId: parseInt(ids[0])
      selectedSchool,
      classId: 0,
      sectionId: selectedClass,
      query
    });
  };

  handleGetComments = ({ postId, typeId }) => {
    const { user, getPostComments } = this.props;
    const { userId } = user;
    getPostComments({ userId, postId, typeId });
  };

  handleLoadMore = () => {
    this.props.increaseLimit({ limit: 10 });
    this.fetchFeed();
  };

  handleDeletePost = ({ feedId, userId }) => {
    this.props.deletePost({ feedId, userId });
  };

  render() {
    const { classes, feed, user } = this.props;
    const { posts, comments, hasMore } = feed;
    const { roleId } = user;

    return (
      <div className={classes.root}>
        <FeedFilter />
        <div className={classes.list} ref={ref => (this.scrollParentRef = ref)}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.handleLoadMore}
            hasMore={hasMore}
            loader={
              <div className={classes.loader} key={0}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            }
            useWindow={false}
            initialLoad={false}
            getScrollParent={() => this.scrollParentRef}
          >
            {posts.map(post => (
              <FeedItem
                key={`${post.feed_id}${post.post_id}`}
                roleId={roleId}
                feedId={post.feed_id}
                userId={post.user_id}
                postId={post.post_id}
                title={post.title}
                body={post.body}
                typeId={post.type_id}
                avatar={post.user_profile_url}
                name={post.name}
                classRoom={post.course_display_name}
                commentsCount={post.post_info.questions_count}
                thanksCount={post.post_info.thanks_count}
                viewCount={post.post_info.view_count}
                created={post.created}
                noteURL={post.note_url}
                noteFullURL={post.full_note_url}
                deck={post.deck}
                uri={post.uri}
                comments={comments}
                handleGetComments={this.handleGetComments}
                handleDeletePost={this.handleDeletePost}
              />
            ))}
            {!hasMore && (
              <div className={classes.allPostsLoaded}>
                <Typography variant="h6" gutterBottom>
                  All posts loaded
                </Typography>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchFeed: feedActions.fetchFeed,
  getPostComments: feedActions.getPostComments,
  increaseLimit: feedActions.increaseLimit,
  deletePost: feedActions.deletePost
};

const mapStateToProps = state => {
  return {
    user: state.user,
    feed: state.feed
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Feed));
