import { API } from "aws-amplify";
import { apiConstants } from "../_constants";

const get = async ({ endpoint, path }) => {
  try {
    return await API.get( endpoint || apiConstants.API_NAME, path);
  } catch (error) {
    console.log("Error: ", error);
  }
};

const del = async ({ path }) => {
  try {
    return await API.del(apiConstants.API_NAME, path);
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const requestServices = {
  get,
  del
};
