//@flow

import { usersConstants } from "../_constants";
import { usersServices, analyticServices } from "../_services";
import { dialogActions } from ".";

const getUserInfo = ({ userId }) => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await usersServices.getUserInfo({ userId });
      const { id, email, first_name, last_name, school, school_id } = response;
      dispatch(
        success({
          userId: id,
          email,
          firstName: first_name,
          lastName: last_name,
          school,
          schoolId: school_id
        })
      );
      analyticServices.event({ category: "Users", action: "Get Info" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Users",
        action: "Get Info error",
        label: err
      });
    }
  };
  function request() {
    return { type: usersConstants.GET_USERS_USER_INFO_REQUEST };
  }
  function success({ userId, email, firstName, lastName, school, schoolId }) {
    return {
      type: usersConstants.GET_USERS_USER_INFO_SUCCESS,
      payload: {
        userId,
        email,
        firstName,
        lastName,
        school,
        schoolId
      }
    };
  }
  function error(err) {
    return {
      type: usersConstants.GET_USERS_USER_INFO_ERROR,
      payload: { error: err }
    };
  }
};

const clearUsersStore = () => {
  return async dispatch => {
    try {
      dispatch(request());
      dispatch(success());
      analyticServices.event({ category: "Users", action: "Clear Store" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Users",
        action: "Clear Store error",
        label: err
      });
    }
  };
  function request() {
    return { type: usersConstants.CLEAR_USERS_STORE_REQUEST };
  }
  function success() {
    return {
      type: usersConstants.CLEAR_USERS_STORE_SUCCESS
    };
  }
  function error(err) {
    return {
      type: usersConstants.CLEAR_USERS_STORE_ERROR,
      payload: { error: err }
    };
  }
};

const getUserPoints = ({ userId }) => {
  return async dispatch => {
    try {
      dispatch(request());
      const points = await usersServices.getUserPoints({ userId });
      dispatch(
        success({
          points
        })
      );
      analyticServices.event({ category: "Users", action: "Get Info" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Users",
        action: "Get Info error",
        label: err
      });
    }
  };
  function request() {
    return { type: usersConstants.GET_USERS_USER_POINTS_REQUEST };
  }
  function success({ points }) {
    return {
      type: usersConstants.GET_USERS_USER_POINTS_SUCCESS,
      payload: {
        points
      }
    };
  }
  function error(err) {
    return {
      type: usersConstants.GET_USERS_USER_POINTS_ERROR,
      payload: { error: err }
    };
  }
};

const getUserService = ({ userId }) => {
  return async dispatch => {
    try {
      dispatch(request());
      const service = await usersServices.getUserService({ userId });
      dispatch(
        success({
          service
        })
      );
      analyticServices.event({ category: "Users", action: "Get Info" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Users",
        action: "Get Info error",
        label: err
      });
    }
  };
  function request() {
    return { type: usersConstants.GET_USERS_USER_SERVICE_REQUEST };
  }
  function success({ service }) {
    return {
      type: usersConstants.GET_USERS_USER_SERVICE_SUCCESS,
      payload: {
        service
      }
    };
  }
  function error(err) {
    return {
      type: usersConstants.GET_USERS_USER_SERVICE_ERROR,
      payload: { error: err }
    };
  }
};

export const usersActions = {
  getUserInfo,
  clearUsersStore,
  getUserPoints,
  getUserService
};
