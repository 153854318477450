//@flow

import update from "immutability-helper";
import { coursesConstants } from "../_constants";

export type CoursesState = {
  courseList: Array<Object>
};

const defaultState = [];

type Action = {
  type: string,
  payload: Object
};

const coursesReducers = (
  state: CoursesState = defaultState,
  { type, payload }: Action
) => {
  switch (type) {
  case coursesConstants.GET_COURSES_SUCCESS:
    return update(state, {
      courseList: { $set: payload.courses },
    });
  default:
    return state;
  }
};

export default coursesReducers;
