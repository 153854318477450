// @flow

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DashboardFilter from "../components/dashboard-filter";
import DashboardTile from "../components/dashboard-tile";
import DashboardVideo from "../components/dashboard-video";
import DashboardSteps from "../components/dashboard-steps";
import DashboardTable from "../components/dashboard-table";
import type { MetricsState } from "../_reducers/metrics.reducers";
import type { UserState } from "../_reducers/user.reducers";
import type { CoursesState } from "../_reducers/courses.reducers";
import { metricsActions, coursesActions } from "../_actions";

const styles = (theme: Object) => ({
  root: {
    overflow: "auto",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    maxHeight: "90vh"
  },
  content: {
    marginBottom: 100,
    padding: theme.spacing.unit * 2
  },
  gridItem: {
    padding: theme.spacing.unit * 2
  }
});

type Props = {
  classes: Object,
  metrics: MetricsState,
  user: UserState,
  courses: CoursesState,
  getMetrics: Function,
  getCourses: Function
};

const Dashboard = ({
  getCourses, 
  getMetrics, 
  user, 
  classes, 
  courses,
  metrics
}: Props) => {
  const { points, flashcards, notes, questions, reminders, thanks } = metrics;
  const { roleId } = user
  const [courseList, setCourseList] = useState([])

  useEffect(() => {
    if (roleId === 1) getCourses()
    getMetrics()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { courseList } = courses
    setCourseList(courseList)
  }, [courses])

  return (
    <div className={classes.root}>
      <DashboardFilter />
      <div className={classes.content}>
        <Grid container spacing={16}>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={6} md={2} className={classes.gridItem}>
              <DashboardTile title="Points" content={points} />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.gridItem}>
              <DashboardTile title="Notes Created" content={notes} />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.gridItem}>
              <DashboardTile title="Questions Created" content={questions} />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.gridItem}>
              <DashboardTile title="Thanks Count" content={thanks} />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.gridItem}>
              <DashboardTile title="Reminders Created" content={reminders} />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.gridItem}>
              <DashboardTile
                title="Flashcards Created"
                content={flashcards}
              />
            </Grid>
          </Grid>
          {roleId === 1 && courseList &&
                <Grid item xs={12} className={classes.gridItem}>
                  <DashboardTable courses={courseList} />
                </Grid>
          }
          {roleId !== 1 &&
                  <Grid item xs={12} sm={6} className={classes.gridItem}> 
                    <DashboardSteps /> 
                  </Grid>
          }
          {roleId !== 1 &&
                  <Grid item xs={12} sm={6} className={classes.gridItem}> 
                    <DashboardVideo /> 
                  </Grid>
          } 
        </Grid>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  getMetrics: metricsActions.getMetrics,
  getCourses: coursesActions.getCourses
};

const mapStateToProps = state => {
  return {
    metrics: state.metrics,
    user: state.user,
    courses: state.courses
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard));
