// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { authComponents } from "../_constants";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const styles = (theme: Object) => ({
  root: {}
});

type Props = {
  classes: Object
};

class Modal extends React.Component<Props> {
  static defaultProps = {
    fullScreen: false,
    fullWidth: false
  };

  render() {
    const {
      classes,
      open = false,
      title = "Title",
      name = "",
      userId = -1,
      roleId,
      children = "Content",
      cancelText = "Cancel",
      okText = "Ok",
      fullScreen,
      fullWidth,
      handleClose
    } = this.props;
    return (
      <div className={classes.root}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={fullWidth}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            disableTypography
            onClose={handleClose}
          >
            <Typography variant="h4">{title}</Typography>
            {authComponents.modal.name.indexOf(roleId) > -1 && (
              <Typography variant="subtitle2">
                <Link to={`/users/${userId}`} style={{ color: "#49afd9" }}>
                  {name}
                </Link>
              </Typography>
            )}
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              {cancelText}
            </Button>
            <Button onClick={handleClose} color="secondary" autoFocus>
              {okText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(Modal));
