// @flow

import React from "react";
import ReactMarkdown from "react-markdown";
import normalizeUrl from "normalize-url";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Object) => ({
  root: {
    "& img": {
      width: "100% !important",
      maxWidth: 400,
      height: "auto !important"
    }
  }
});

type Props = {
  classes: Object,
  description: string,
  uri: string
};

class PostDetail extends React.Component<Props> {
  render() {
    const { classes, description, uri } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="body1" component="h3">
          {uri === "" ? (
            <ReactMarkdown source={description} escapeHtml={false} />
          ) : (
            <a
              href={normalizeUrl(uri)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {uri}
            </a>
          )}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(PostDetail);
