// @flow

import { userConstants } from "../_constants";
import { userServices, analyticServices } from "../_services";
import { dialogActions } from ".";

const getUserProfile = () => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await userServices.getUserProfile();
      const { user, classes } = response;
      const { id, school_id, role_id } = user;
      dispatch(
        success({
          userId: id,
          schoolId: school_id,
          roleId: role_id,
          classes
        })
      );
      analyticServices.event({ category: "User", action: "Profile Fetched" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({
        category: "Feed",
        action: "Profile Error",
        label: err
      });
    }
  };
  function request() {
    return { type: userConstants.GET_USER_PROFILE_REQUEST };
  }
  function success({ userId, schoolId, roleId, classes }) {
    return {
      type: userConstants.GET_USER_PROFILE_SUCCESS,
      payload: { userId, schoolId, roleId, classes }
    };
  }
  function error(err) {
    return {
      type: userConstants.GET_USER_PROFILE_ERROR,
      payload: { error: err }
    };
  }
};

export const userActions = {
  getUserProfile
};
