// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import teal from "@material-ui/core/colors/teal";

const styles = (theme: Object) => ({
  root: {
    ...theme.mixins.gutters(),
    // margin: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    maxHeight: 500,
    // minWidth: "100vw",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    overflow: "hidden"
  },
  list: {
    overflow: "auto"
  },
  paper: {
    backgroundColor: teal[500],
    ...theme.mixins.gutters(),
    margin: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  item: {
    display: "flex"
  },
  flex: {
    flex: 1
  }
});

type Props = {
  classes: Object,
  points: Array<Object>
};

class UserPointsDetail extends React.Component<Props> {
  renderType = ({ typeId }) => {
    switch (typeId) {
    case 1:
      return "Upload Photo Note";
    case 2:
      return "Create Study Deck";
    case 3:
      return "Share Link";
    case 4:
      return "Ask Question";
    case 5:
      return "Expired Question";
    case 6:
      return "Chose Best Answer";
    case 7:
      return "Received Best Answer";
    case 8:
      return "Received Thanked Answer";
    case 9:
      return "Posts Answer";
    case 10:
      return "Complete To Do";
    case 11:
      return "Daily Reward";
    case 12:
      return "Weekly Goal";
    case 13:
      return "Bookmarked Post";
    case 14:
      return "Create Account Init";
    case 15:
      return "Thank Post";
    case 16:
      return "Referral";
    default:
      return typeId;
    }
  };

  render() {
    const { classes, points } = this.props;
    return (
      <div>
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3" paragraph>
            Points
          </Typography>
          <div className={classes.list}>
            {points.map((item, index) => (
              <Paper
                key={`${item.date}-${index}`}
                elevation={0}
                className={classes.paper}
              >
                <div className={classes.content}>
                  <div className={classes.item}>
                    <Typography variant="subtitle1" className={classes.flex}>
                      {`${item.points} points`}
                    </Typography>
                    <Typography variant="subtitle1">
                      {this.renderType({ typeId: item.type_id })}
                    </Typography>
                  </div>
                  <div className={classes.item}>
                    <Typography variant="subtitle2" className={classes.flex}>
                      {item.date}
                    </Typography>
                    <Typography variant="subtitle2">{`Object ID: ${item.object_id}`}</Typography>
                  </div>
                </div>
              </Paper>
            ))}
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(UserPointsDetail);
