//@flow

import update from "immutability-helper";
import { authConstants, userConstants } from "../_constants";

export type UserState = {
  userId: string,
  role: string,
  roleId: number,
  schoolId: number,
  userClasses: Array<Object>
};

const defaultState = {
  userId: "",
  role: "",
  roleId: -1,
  schoolId: -1,
  userClasses: []
};

type Action = {
  type: string,
  payload: Object
};

const userReducers = (
  state: UserState = defaultState,
  { type, payload }: Action
) => {
  switch (type) {
  case authConstants.CHECK_USER_ERROR:
    return update(state, {
      userId: { $set: "" },
      role: { $set: "" },
      schoolId: { $set: -1 },
      roleId: { $set: -1 },
      userClasses: { $set: [] }
    });
  case authConstants.LOG_IN_USER_REQUEST:
    return update(state, {
      userId: { $set: "" },
      role: { $set: "" },
      schoolId: { $set: -1 },
      roleId: { $set: -1 },
      userClasses: { $set: [] }
    });
  case userConstants.GET_USER_PROFILE_SUCCESS:
    return update(state, {
      userId: { $set: payload.userId },
      schoolId: { $set: payload.schoolId },
      roleId: { $set: payload.roleId },
      userClasses: { $set: payload.classes }
    });
  case authConstants.LOG_OUT_USER_SUCCESS:
    return update(state, { $set: defaultState });
  default:
    return state;
  }
};

export default userReducers;
