import ReactGA from "react-ga";
import amplitude from "amplitude-js";

const set = async ({ field, value }) => {
  try {
    ReactGA.set({ [field]: value });
  } catch (error) {
    console.log("Error: ", error);
  }
};

const pageView = ({ path }) => {
  try {
    ReactGA.pageview(path);
  } catch (error) {
    console.log("Error", error);
  }
};

const event = ({ category, action, label = "" }) => {
  try {
    ReactGA.event({
      category,
      action,
      label
    });
  } catch (error) {
    console.log("Error", error);
  }
};

const init = key => {
  try {
    amplitude
      .getInstance("web")
      .init(key, null, { includeReferrer: true, batchEvents: true });
  } catch (err) {
    console.log(err);
  }
};

const logEvent = ({ event, props }) => {
  try {
    amplitude.getInstance("web").logEvent(event, props);
  } catch (err) {
    console.log(err);
  }
};

export const analyticServices = {
  set,
  pageView,
  event,
  init,
  logEvent
};
