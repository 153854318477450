//@flow

import { feedConstants } from "../_constants";
import { feedServices, analyticServices } from "../_services";
import { dialogActions } from ".";

const fetchFeed = ({
  userId,
  index,
  limit,
  schoolId,
  postTypes,
  from,
  selectedSchool,
  classId,
  sectionId,
  query
}) => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await feedServices.fetchFeed({
        userId,
        index,
        limit,
        schoolId,
        postTypes,
        from,
        selectedSchool,
        classId,
        sectionId,
        query
      });
      const { posts = [] } = response;
      dispatch(success({ posts }));
      analyticServices.event({ category: "Feed", action: "Fetched" });
    } catch (err) {
      dispatch(error(err));
      analyticServices.event({
        category: "Feed",
        action: "Fetched Error",
        label: err
      });
      // const { message = "There was an unknown error" } = err;
      // dispatch(dialogActions.showDialog({ title: "Error", content: message }));
    }
  };
  function request() {
    return { type: feedConstants.FETCH_FEED_REQUEST };
  }
  function success({ posts }) {
    return {
      type: feedConstants.FETCH_FEED_SUCCESS,
      payload: { posts }
    };
  }
  function error(err) {
    return { type: feedConstants.FETCH_FEED_ERROR, payload: { error: err } };
  }
};

const getPostComments = ({ userId, postId, typeId }) => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await feedServices.getPostComments({
        userId,
        postId,
        typeId
      });
      const { comments = [] } = response;
      dispatch(success({ comments }));
      analyticServices.event({ category: "Feed", action: "Comments Fetched" });
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
      analyticServices.event({ category: "Feed", action: "Error", label: err });
    }
  };
  function request() {
    return { type: feedConstants.GET_POST_COMMENTS_REQUEST };
  }
  function success({ comments }) {
    return {
      type: feedConstants.GET_POST_COMMENTS_SUCCESS,
      payload: { comments }
    };
  }
  function error(err) {
    return {
      type: feedConstants.GET_POST_COMMENTS_ERROR,
      payload: { error: err }
    };
  }
};

const updateFilterField = ({ name, value }) => {
  return async dispatch => {
    try {
      dispatch(request());
      dispatch(success({ name, value }));
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
    }
  };
  function request() {
    return { type: feedConstants.UPDATE_FILTER_FIELD_REQUEST };
  }
  function success({ name, value }) {
    return {
      type: feedConstants.UPDATE_FILTER_FIELD_SUCCESS,
      payload: { name, value }
    };
  }
  function error(err) {
    return {
      type: feedConstants.UPDATE_FILTER_FIELD_ERROR,
      payload: { error: err }
    };
  }
};

const increaseLimit = ({ limit }) => {
  return async dispatch => {
    try {
      dispatch(request());
      dispatch(success({ limit }));
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
    }
  };
  function request() {
    return { type: feedConstants.INCREASE_LIMIT_REQUEST };
  }
  function success({ limit }) {
    return {
      type: feedConstants.INCREASE_LIMIT_SUCCESS,
      payload: { limit }
    };
  }
  function error(err) {
    return {
      type: feedConstants.INCREASE_LIMIT_ERROR,
      payload: { error: err }
    };
  }
};

const deletePost = ({ feedId, userId }) => {
  return async dispatch => {
    try {
      dispatch(request());
      const response = await feedServices.deletePost({
        feedId,
        userId
      });
      console.log(response);
      dispatch(success({ feedId, userId }));
    } catch (err) {
      dispatch(error(err));
      const { message = "There was an unknown error" } = err;
      dispatch(dialogActions.showDialog({ title: "Error", content: message }));
    }
  };
  function request() {
    return { type: feedConstants.DELETE_POST_REQUEST };
  }
  function success({ feedId, userId }) {
    return {
      type: feedConstants.DELETE_POST_SUCCESS,
      payload: { feedId, userId }
    };
  }
  function error(err) {
    return {
      type: feedConstants.DELETE_POST_ERROR,
      payload: { error: err }
    };
  }
};

export const feedActions = {
  fetchFeed,
  getPostComments,
  updateFilterField,
  increaseLimit,
  deletePost
};
