// @flow

import React from "react";
import classNames from "classnames";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import teal from "@material-ui/core/colors/teal";
import red from "@material-ui/core/colors/red";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "./modal";
import PostDetail from "./postDetail";
import FlashCardsDetail from "./flashCardsDetail";
import PhotoNoteDetail from "./photo-note-detail";
import Comments from "./comments";
import LinkPost from "../assets/ic_link_post.svg";
import QuestionPost from "../assets/ic_question_post.svg";
import FlashcardPost from "../assets/ic_flashcard_post.svg";
import { authComponents } from "../_constants";

const styles = (theme: Object) => ({
  root: {
    // flex: "1 1 0"
    // maxWidth: 800
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative"
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",
    flex: 1
  },
  avatar: {
    // margin: theme.spacing.unit
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  bigAvatar: {
    width: 60,
    height: 60,
    marginBottom: 10
  },
  content: {
    paddingLeft: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  flashCardsImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  deckCount: {
    width: "100%",
    backgroundColor: teal[500],
    textAlign: "center",
    padding: 2,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  notesImage: {
    minHeight: 75,
    maxHeight: 75,
    minWidth: 75,
    maxWidth: 75,
    borderRadius: 5
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    },
    color: theme.palette.text.primary
  },
  buttonXS: {
    position: "absolute",
    right: -24,
    top: -24
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  }
});

type Props = {
  classes: Object,
  roleId: number,
  feedId: number,
  userId: number,
  postId: number,
  title: string,
  body: string,
  typeId: number,
  avatar: string,
  name: string,
  classRoom: string,
  commentsCount: number,
  thanksCount: number,
  viewCount: number,
  created: string,
  noteURL: string,
  noteFullURL: string,
  deck: Array<Object>,
  uri: string,
  comments: Array<Object>,
  handleClick: Function,
  handleDeletePost: Function
};

class FeedItem extends React.Component<Props> {
  state = {
    open: false,
    openDelete: false
  };

  handleClick = () => {
    const { postId, typeId, handleGetComments } = this.props;
    this.setState({ open: true });
    handleGetComments({ postId, typeId });
  };

  handleModalClose = () => {
    this.setState({ open: false });
  };

  handleOpenDelete = () => {
    this.setState({ openDelete: true });
  };

  handleClose = () => {
    this.setState({ openDelete: false });
  };

  handleDeletePost = () => {
    const { feedId, userId, handleDeletePost } = this.props;
    this.setState({ openDelete: false });
    handleDeletePost({ feedId, userId });
  };

  renderPostType = ({ typeId }: { typeId: number }) => {
    const { classes, noteURL, deck } = this.props;
    switch (typeId) {
    case 3:
      return (
        <div className={classes.flashCardsImage}>
          <img src={FlashcardPost} alt="flashcards" />
          <div className={classes.deckCount}>
            <Typography variant="caption">{`${deck.length} ${
                deck.length === 1 ? "Card" : "Cards"
              }`}</Typography>
          </div>
        </div>
      );
    case 4:
      return (
        <div
          className={classes.notesImage}
          style={{
            // $FlowIgnore
            background: `url(${noteURL})`,
            backgroundSize: "cover"
          }}
        />
      );
    case 5:
      return <img src={LinkPost} alt="link" />;
    case 6:
      return <img src={QuestionPost} alt="question" />;
    default:
      return "";
    }
  };

  listToTree = (comments: Array<Object>) => {
    const commentsById = new Map();
    const commentsTree = [];
    comments.forEach(comment => {
      commentsById.set(comment.id, { ...comment, children: [] });
    });

    commentsById.forEach((comment, key) => {
      if (comment.root_comment_id) {
        const rootComment = commentsById.get(comment.root_comment_id);
        const parentComment = commentsById.get(comment.parent_comment_id);
        if (rootComment && rootComment.children) {
          comment.replyTo = parentComment ? parentComment.user.first_name : "";
          rootComment.children.push(comment);
        }
      } else {
        commentsTree.push(comment);
      }
    });

    return commentsTree;
  };

  render() {
    const {
      classes,
      roleId,
      userId,
      title,
      body,
      typeId,
      avatar,
      name,
      classRoom,
      commentsCount,
      thanksCount,
      viewCount,
      created,
      deck,
      uri,
      noteFullURL,
      comments
    } = this.props;
    const { open, openDelete } = this.state;
    const initials = name !== "" ? name.match(/\b(\w)/g).join("") : "";
    const commentTree = this.listToTree(comments);

    return (
      <div className={classes.root}>
        <Paper
          className={classes.paper}
          elevation={1}
          onClick={this.handleClick}
        >
          <div className={classes.avatar}>
            {authComponents["feed-item"].avatar.indexOf(roleId) > -1 ? (
              <Avatar
                alt={name}
                src={avatar}
                className={classNames(classes.bigAvatar)}
              >
                {initials.toUpperCase()}
              </Avatar>
            ) : (
              ""
            )}
            <Hidden smUp>
              <div className={classes.thumbnail}>
                {this.renderPostType({ typeId })}
              </div>
            </Hidden>
          </div>
          <div className={classes.content}>
            <Typography variant="h5" style={{ wordBreak: "break-word" }}>
              {title}
            </Typography>
            <Typography component="p">{`${
              authComponents["feed-item"].name.indexOf(roleId) > -1
                ? `${name} | `
                : ""
            }${classRoom}`}</Typography>
            <Typography component="p">
              {`${
                authComponents["feed-item"].stats.indexOf(roleId) > -1
                  ? `${commentsCount} comments | ${thanksCount} thanks | ${viewCount} views`
                  : ""
              }`}
            </Typography>
            <Typography component="p">{moment(created).fromNow()}</Typography>
          </div>
          <Hidden xsDown>
            <div className={classes.thumbnail}>
              {this.renderPostType({ typeId })}
            </div>
          </Hidden>
        </Paper>
        {authComponents["feed-item"].delete.indexOf(roleId) > -1 && (
          <Button
            variant="fab"
            className={classNames(classes.button, classes.buttonXS)}
            onClick={this.handleOpenDelete}
          >
            <DeleteIcon />
          </Button>
        )}
        <Modal
          open={open}
          title={title}
          name={name}
          userId={userId}
          roleId={roleId}
          fullWidth
          handleClose={this.handleModalClose}
        >
          <PostDetail description={body} uri={uri} />
          {typeId === 4 && <PhotoNoteDetail url={noteFullURL} />}
          {typeId === 3 && <FlashCardsDetail deck={deck} />}
          {authComponents["feed-item"].comments[typeId].indexOf(roleId) >
            -1 && (
            <Comments typeId={typeId} roleId={roleId} comments={commentTree} />
          )}
        </Modal>
        <Dialog
          open={openDelete}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this post?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are going to delete this post and all the points associated
              with it
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleDeletePost} color="secondary" autoFocus>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(FeedItem);
