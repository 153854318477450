//@flow

export const feedConstants = {
  FETCH_FEED_REQUEST: "FETCH_FEED_REQUEST",
  FETCH_FEED_SUCCESS: "FETCH_FEED_SUCCESS",
  FETCH_FEED_ERROR: "FETCH_FEED_ERROR",
  GET_POST_COMMENTS_REQUEST: "GET_POST_COMMENTS_REQUEST",
  GET_POST_COMMENTS_SUCCESS: "GET_POST_COMMENTS_SUCCESS",
  GET_POST_COMMENTS_ERROR: "GET_POST_COMMENTS_ERROR",
  UPDATE_FILTER_FIELD_REQUEST: "UPDATE_FILTER_FIELD_REQUEST",
  UPDATE_FILTER_FIELD_SUCCESS: "UPDATE_FILTER_FIELD_SUCCESS",
  UPDATE_FILTER_FIELD_ERROR: "UPDATE_FILTER_FIELD_ERROR",
  INCREASE_LIMIT_REQUEST: "INCREASE_LIMIT_REQUEST",
  INCREASE_LIMIT_SUCCESS: "INCREASE_LIMIT_SUCCESS",
  INCREASE_LIMIT_ERROR: "INCREASE_LIMIT_ERROR",
  DELETE_POST_REQUEST: "DELETE_POST_REQUEST",
  DELETE_POST_SUCCESS: "DELETE_POST_SUCCESS",
  DELETE_POST_ERROR: "DELETE_POST_ERROR"
};
