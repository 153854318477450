// @flow

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";
import Header from "../containers/header";
import ReportsContainer from "../containers/reports";
import { analyticServices } from "../_services";

const styles = (theme: Object) => ({
  root: {}
});

type Props = {
  classes: Object
};

class Reports extends React.Component<Props> {
  componentDidMount = () => {
    const { match = {} } = this.props;
    const { path = "" } = match;
    analyticServices.pageView({ path });
  };

  render() {
    const { classes } = this.props;
    return (
      <Header>
        <div className={classes.root}>
          <ReportsContainer />
        </div>
      </Header>
    );
  }
}

export default withRoot(withStyles(styles)(Reports));
