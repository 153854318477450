//@flow

import { requestServices } from ".";

const getUserProfile = async () => {
  try {
    return requestServices.get({ path: "/user" });
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const userServices = {
  getUserProfile
};
