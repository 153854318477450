//@flow

export const reportsConstants = {
  FETCH_REPORTS_REQUEST: "FETCH_REPORTS_REQUEST",
  FETCH_REPORTS_SUCCESS: "FETCH_REPORTS_SUCCESS",
  FETCH_REPORTS_ERROR: "FETCH_REPORTS_ERROR",
  UPDATE_FILTER_TAB_REQUEST: "UPDATE_FILTER_TAB_REQUEST",
  UPDATE_FILTER_TAB_SUCCESS: "UPDATE_FILTER_TAB_SUCCESS",
  UPDATE_FILTER_TAB_ERROR: "UPDATE_FILTER_TAB_ERROR"
};
