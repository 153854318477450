//@flow

import { requestServices } from ".";

const getUserInfo = async ({ userId }) => {
  try {
    return requestServices.get({ path: `/user/profile/${userId}` });
  } catch (error) {
    console.log("Error: ", error);
  }
};

const getUserPoints = async ({ userId }) => {
  try {
    return requestServices.get({ path: `/user/${userId}/log/points` });
  } catch (error) {
    console.log("Error: ", error);
  }
};

const getUserService = async ({ userId }) => {
  try {
    return requestServices.get({ path: `/user/${userId}/log/service` });
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const usersServices = {
  getUserInfo,
  getUserPoints,
  getUserService
};
