// @flow

import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import type { UsersState } from "../_reducers/users.reducers";
import { usersActions } from "../_actions";
import UserProfile from "../components/user-profile";
import UserPointsDetail from "../components/user-points-detail";
// import UserServiceDetail from "../components/user-service-detail";

const styles = (theme: Object) => ({
  root: {
    // display: "flex",
    maxHeight: "100vh",
    // flexDirection: "column",
    overflowY: "auto",
    paddingBottom: 0
    // flexWrap: "wrap"
  }
  // content: {
  //   display: "flex",
  //   flexWrap: "wrap",
  //   overflow: "auto",
  //   marginBottom: 100,
  //   padding: theme.spacing.unit * 2
  // }
});

type Props = {
  classes: Object,
  users: UsersState,
  userId: number,
  getUserInfo: Function
};

class Users extends React.Component<Props> {
  componentDidMount = () => {
    const { userId, getUserInfo, getUserPoints } = this.props;
    getUserInfo({ userId });
    getUserPoints({ userId });
    // getUserService({ userId });
  };

  componentWillUnmount = () => {
    this.props.clearUsersStore();
  };

  render() {
    const { classes, users } = this.props;
    const {
      userId,
      email,
      firstName,
      lastName,
      school,
      schoolId,
      points
      // service
    } = users;

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6}>
            <UserProfile
              userId={userId}
              email={email}
              firstName={firstName}
              lastName={lastName}
              school={school}
              schoolId={schoolId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserPointsDetail points={points} />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <UserServiceDetail service={service} />
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getUserInfo: usersActions.getUserInfo,
  clearUsersStore: usersActions.clearUsersStore,
  getUserPoints: usersActions.getUserPoints,
  getUserService: usersActions.getUserService
};

const mapStateToProps = state => {
  return {
    users: state.users
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Users));
